/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css";
@import "@angular/material/prebuilt-themes/pink-bluegrey.css";
/** Colors variables */
.sb-default .sb-wrapper {
  --sb-color: #fff;
  --sb-background: var(--button-color);
  --sb-border-radius: 4px;
  --sb-overflow: hidden;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text {
  --sb-text-padding: 0 1em 0 0;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* font-family: Pacifico,Pacifico,Pacifico !important; */
}

.style-success {
  background: #1ace44 !important;
  border: 1px solid #c3e6cb;
  color: white;
  margin-top: 350px !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.mat-calendar-table,
.mat-calendar-table-header,
.mat-calendar-body-cell,
.mat-calendar-body-cell-content,
.mat-calendar,
.mat-calendar-previous-button,
.mat-calendar-next-button,
.mat-calendar-body-label {
  background-color: #fff !important;
  color: #333 !important;
}

.mat-datepicker-content .mat-calendar {
  height: 380px !important;
}

.mat-form-field-wrapper {
  padding: 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 !important;
  border-top: 0 !important;
}

.mat-calendar-body-disabled {
  opacity: 0.4 !important;
}

.mat-input-element {
  width: -webkit-fill-available !important;
}

.cdk-overlay-container {
  overflow: auto;
}

.overlay {
  z-index: 1000000 !important;
  background-color: rgba(0, 0, 0, 0.5);
}

/* For checkout buttons */
.br-40px {
  border-radius: 40px !important;
}

.br-10px {
  border-radius: 10px !important;
}

.width1rem {
  width: 1rem !important;
}

.height1rem {
  height: 1rem !important;
}

.width1-5rem {
  width: 1.5rem !important;
}

.height1-5rem {
  height: 1.5rem !important;
}

.cursor-pointer {
  cursor: pointer;
}